<template>
  <v-navigation-drawer v-model="drawer" @click="drawer = !drawer" clipped permanent>

    <!--  -->
    <v-list active-color="blue" class="responsiveMenu">
      <v-list>
        <v-list-item :prepend-avatar="photoURL" :subtitle="email" :title="displayName" nav></v-list-item>
      </v-list>

      <v-list-item @click="cleanSelect()" link to="/index" prepend-icon="mdi-home" title="Inicio">
      </v-list-item>

      <!-- <v-list-item v-if="alterar" @click="cleanSelect()" link to="/permissoes" prepend-icon="mdi-account"
        title="Permissões">
      </v-list-item> -->

      <div v-for="item in sistemas" :key="item.linksistema">
        <v-list-item @click="cleanSelect()" v-if="permissoes[`${item.nomesistema}`]" link :to="item.linksistema"
          :prepend-icon="item.iconsistema">
          <v-list-item-title class="text-capitalize">{{ item.nomesistema }}</v-list-item-title>
        </v-list-item>

      </div>
    </v-list>

    <v-list-item @click.stop="gaveta()" key="gaveta" prepend-icon="mdi-cards" title="Relatorios" link
      :color="active ? 'blue' : ''">

    </v-list-item>
    <v-divider></v-divider>
    <v-list>
      <v-list-item>Versão: 1.{{ version }}<br/>
        &copy; {{ new Date().getFullYear() }} - Itepbrasil
      </v-list-item>
    </v-list>

  </v-navigation-drawer>

  <div v-if="barra2">
    <v-navigation-drawer @click="barra2 = !barra2" v-model="barra2" style="z-index:1" permanent>
      <!-- Barra 2 -->
      <div v-for="item in relatorios" :key="item.linksistema">
        <v-list-item @click="pushRelSelect(item.workspaceid,item.reportid)" 
          :prepend-icon="item.iconsistema">
          <v-list-item-title class="text-capitalize">{{ item.nomesistema }}</v-list-item-title>
        </v-list-item>

      </div>
      <!-- <v-list-item @click="drawer = !drawer" to="/reportbi">
        Geral
      </v-list-item>
      <v-list-item @click="drawer = !drawer" to="/reportbi">
        Clientes
      </v-list-item> -->
    </v-navigation-drawer>

  </div>

  <v-app-bar app>

    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

    <v-toolbar-title>Sistema Financeiro</v-toolbar-title>

    <v-col>
        <v-select label="Empresa" variant="solo" 
            @update:modelValue="ChangeCompany"
            v-model="companySelected"
            :items="empresas"></v-select>

    </v-col>

    <v-menu transition="slide-y-transition">
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" :prepend-avatar="photoURL" max-width="70">
        </v-list-item>
      </template>
      <v-list>
        <v-list-item>

          <v-list-item-title> Perfil.</v-list-item-title>
        </v-list-item>
        <v-list-item @click="handleSignOut">

          <v-list-item-title> Sair</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>


</template>

<script setup>
import { onMounted, ref } from "vue";
import { getAuth, signOut } from "firebase/auth";
import router from "@/router";

const avatar = require("../assets/avatar.png");

const config = require("../version.json");



//import path from "path";
const displayName = ref();
const photoURL = ref();
const email = ref()
const version = ref()


onMounted(() => {

  const auth = getAuth();
  const user = auth.currentUser;
  displayName.value = (user.displayName) ? user.displayName : user.email;
  photoURL.value = (user.photoURL) ? user.photoURL : avatar;
  email.value = (!user.displayName) ? '' : user.email;
  version.value = config.version;   
})

let auth;
auth = getAuth();

const handleSignOut = () => {
  signOut(auth).then(() => {
    window.localStorage.removeItem("token");
    router.push("/");
  }

  )
};
</script>
<script >
export default {
  data: () => ({
    rw: "220",
    drawer: true,
    sistemas: [],
    relatorios: [],
    active: false,
    permissoes: [],
    barra2: false,
    alterar: false,
    id: null,
    empresas: [],
    companySelected: localStorage.getItem('company')
  }),
  methods: {
    gaveta() {
      this.barra2 = !this.barra2;
      if (this.barra2) {
        this.active = true;
      }
      else {
        this.active = false;
      }
    },
    cleanSelect() {
      this.active = false;
      this.barra2 = false;    
    },    
    async pushRelSelect(workspaceid,reportid) {
      this.active = false;
      this.barra2 = false;
      await this.$router.push({ path: '/reportbi/'+workspaceid+'/'+reportid });
    },
    signOutUser() {
      const auth = getAuth();
      signOut(auth).then(() => {
        window.localStorage.removeItem("token");
        router.push("/");
      }
      )
    },
    async getSystem() {
      const company = localStorage.getItem("company");
      let companyId = company.split("-");
      companyId = companyId[0];


      const req = await fetch(`${this.$baseUrlApiMain}/sistema/read/${companyId}`);
      const data = await req.json()
      this.sistemas = data;
    },
    async getRelatorios() {
      const req = await fetch(`${this.$baseUrlApiMain}/sistema/relatorios`);
      const data = await req.json()
      this.relatorios = data;
    },
    async token() {      
                const auth = getAuth();
                const uu = auth.currentUser;
                const respon = await fetch(`${this.$baseUrlApiMain}/token`,{
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { "uid": `${uu.email}`}
                });
                const response = await respon.json()
                localStorage.setItem('token', response.token);
                console.log('5-token');
        },
    async getId() {
      const token = localStorage.getItem("token");
            if (!token){
                this.token();                
            }
      const auth = getAuth();
      const u = auth.currentUser;
      const req = await fetch(`${this.$baseUrlApiMain}/cadastro/email?email=${u.email}`,{
                method: "GET",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
                });
                if (req.error=='Token invalid' || req.error=='No token provided'){
                  window.localStorage.removeItem("token");
                  //console.log('removido');
                  this.token();                    
            }
      const data = await req.json();
        if (data[0].login) {
          this.alterar = data[0].alterar
          this.permissoes = data[0].permissoes
         
        } else {
          this.signOutUser()
        }
    },
    async ListCompany() {

        const auth = getAuth();
        const u = auth.currentUser;

          const token = localStorage.getItem("token");
          if (!token){
              this.$token();                
          }
         
          const companyselected = localStorage.getItem('company');  
          console.log(companyselected);

          const req = await fetch(`${this.$baseUrlApiMain}/empresa/readtocadastro/${u.email}`,{
              method: "GET",
              headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` }
              });
          const data = await req.json()
          for (let i = 0; i < data.length; i++) {
            this.empresas.push(data[i].id + '-'+ data[i].razaosocial );
        }
        
      },
    ChangeCompany: function(object) {
      localStorage.setItem('company', object);   
      // :data-selected="selectedOption"
       window.location.reload();
    },
  },   
  watch: {
        alertSucces(val) {
            val && setTimeout(() => {
                this.alertSucces = false;
            }, 2000);
        },               
        barra2(b) {
          if (b) {
            this.rw = "130";

          }
          else {
            this.rw = "220";
          }
        }
    },
  beforeMount() {

    this.$isPermited('empresas');     
    this.getSystem();
    this.getId();
    this.getRelatorios(); 
    this.ListCompany();
  }
}
</script>

<style>
.barra2 {
  display: none;
  width: 0;
}
</style>